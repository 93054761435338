import { isEmpty } from 'lodash';
import slugify from 'slugify';
import appConfig from '../data/appConfig.json';

const categoryUtils = {
    categoryApi: async (category) => {
        const response = await fetch(
            `/api/categories/category-${slugify(category)}.json`,
        ).then((res) => res.json());

        return response;
    },
    categoriesGroupsApi: async () => {
        const response = await fetch(`/api/categories/categories-groups.json`).then((res) =>
            res.json(),
        );

        return response;
    },

    categoryListByUserPreferences: ({ categoryList, activeGroups }) => {
        const { groups: defaultGroups } = appConfig;

        const setGroups = [];

        if (isEmpty(activeGroups)) {
            setGroups.push(...defaultGroups);
        } else {
            setGroups.push(...activeGroups);
        }

        const categoryListByAvailableGroups = categoryList.filter((category) => {
            const { availableGroups } = category;

            return availableGroups.some((group) => setGroups.includes(group));
        });

        const categoryListResults = [];

        categoryListByAvailableGroups.forEach((category) => {
            const { title, productsByGroup } = category;
            let productsTotalCountByUserPreferences = 0;

            setGroups.forEach((group) => {
                const getProductsByGroup = productsByGroup.find(
                    (details) => details.group === group,
                );
                if (getProductsByGroup) {
                    productsTotalCountByUserPreferences += getProductsByGroup?.totalCount;
                }
            });

            if (!isEmpty(productsByGroup)) {
                categoryListResults.push({
                    title,
                    totalCount: productsTotalCountByUserPreferences,
                });
            }
        });

        return categoryListResults;
    },

    sortCategoriesByProductsAmountHightToLow: (categoryList) =>
        [...categoryList].sort((a, b) => b.totalCount - a.totalCount),

    sortCategoriesByProductsAmountLowToHigh: (categoryList) =>
        [...categoryList].sort((a, b) => a.totalCount - b.totalCount),

    getCategorySlug: (category) => slugify(category, { lower: true, strict: true }),

    getPathToCategory: (category, country) =>
        `category-${slugify(category, { lower: true, strict: true })}-${country}`,
};

export default categoryUtils;
