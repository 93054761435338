import { rem, transparentize } from 'polished';
import styled from 'styled-components';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';

const CategoryListListFadeContainer = styled.div`
    height: ${({ theme, headerOffset }) =>
        `calc(100vh - ${Math.ceil(
            theme.header.height +
                theme.site.brandSearch.height +
                theme.site.stage.paddingTop +
                headerOffset,
        )}px)`};
    padding: 0 ${rem(16)};
    position: relative;

    ${media.md`
        height: ${({ theme }) =>
            `calc(90vh - ${rem(
                theme.header.height + theme.site.brandSearch.height + theme.site.stage.paddingTop,
            )})`};
    `}

    &:after,
    &:before {
        content: '';
        height: 20px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &:after {
        background-image: linear-gradient(
            to bottom,
            ${({ theme }) => transparentize(1, theme.site.stage.backgroundColor)} 10%,
            ${({ theme }) => theme.site.stage.backgroundColor} 50%
        );
        bottom: -2px;
    }

    ${media.md`    
        &:before {
            background-image: linear-gradient(
                to top,
                ${({ theme }) => transparentize(1, theme.site.stage.backgroundColor)} 10%,
                ${({ theme }) => theme.site.stage.backgroundColor} 50%
            );
            top: -2px;
        }
    `}
`;

export default CategoryListListFadeContainer;
